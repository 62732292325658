import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { RiWifiOffLine } from "react-icons/ri";

const OnlineNotifier = () => {
  const [isOnline, setIsOnline] = React.useState(true);

  React.useEffect(() => {
    const handleOffline = () => {
      setIsOnline(false);
    };

    const handleOnline = () => {
      setIsOnline(true);
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  if (isOnline) {
    return null;
  }
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="absolute left-1/2 top-0  mt-6 -translate-x-1/2"
      >
        <div
          className="z-[60] max-w-sm rounded-md border border-gray-700 bg-black shadow-lg"
          role="alert"
        >
          <div className="flex items-center gap-4 p-2 sm:p-4">
            <RiWifiOffLine className="mt-1 text-2xl text-gray-400" />
            <div className="">
              <p className="text-sm text-white">
                It&apos;s seems that you are offline
              </p>
              <p className="text-sm text-gray-400">
                Please try again later or contact help@knackapp.com for
                assistance
              </p>
            </div>
            <button
              type="button"
              className="text-sm font-semibold text-[#1D4ED8] transition-all"
              onClick={() => window.location.reload()}
            >
              Retry
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default OnlineNotifier;
