// auth flow
export const SIGN_UP = "auth/local/signup";
export const SIGN_IN = "auth/local/signin";
export const LOG_OUT = "logout";
export const RESEND_OTP = "auth/local/resendotp";
export const VERIFY_OTP = "auth/local/verifyotp";
export const FORGOT_PASSWORD = "auth/forgot-password";
export const CHANGE_PASSWORD = "auth/change-password";
export const GOOGLE_AUTH = "auth/google";
export const FACEBOOK_AUTH = "auth/facebook";
export const APPLE_AUTH = "auth/apple";
export const VALIDATE_HANDLE = "validate/handle";
// prisms
export const CREATE_PRISM = "prisms/new";
export const GET_ALL_TEMPLATES = "templates/all";
export const GET_KNACKS = "knacks/deployed";
// this is for maps
export const GET_GROUP_KNACKS = "categories/knacks";
export const GET_USER = "auth/user";
export const BUSINESS = "business";
export const BUSINESS_AUTH_CHANGE_PASSWORD = "business/auth/change-password";
// for prisms
export const TEMPLATE_SKILLS = "categories/skills";
export const TEMPLATE_CATEGORIES = "categories/templates";
export const PRISMS = "prisms";
export const UPLOAD_IMAGES = "upload-images";
// pipelines
export const PIPELINE = "pipelines";
// channels
export const CHANNELS = "channels";
// maps
export const MAPS = "maps";
// scores
export const SCORES = "scores";

// Talents
export const TALENT = "talents/profile";
export const UPDATE_TALENT = "talents";
export const CHARGE = "charge";
export const BUY_SUPERLIKES = "talents/buy/superlikes";
export const GET_HANDLES = "talents/handles/all";
export const GAMES = "games/user-game-infos";

// get all users in session
export const GETALLUSERS = "session";

// Talent Feed for prisms
export const BASE_TALENTFEED_URL = "/prisms/talentfeed/";

// categories
export const BASE_CATEGORIES_URL = "/categories";

export const SUBSCRIPTIONS = "subscriptions";
