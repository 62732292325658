/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

// initial state for prism slice
const initialState = {
  prismObj: {
    template: "",
    customTemplate: [],
    title: "",
    description: "",
    owner: "",
    requiredGames: [],
    coverImageUrl: "",
    logoImageUrl: "",
  },
  selectedKnacks: [],
  selectedKnacksTemplate: [],
  idOnEdit: "",
  toUploadFiles: {
    logo: "",
    displayImage: "",
  },
  prismOnView: null,
  userInView: null,
  query: '',
  results: [],
  prismList: []
};

// prism slice
export const prismSlice = createSlice({
  name: "prism",
  initialState,
  reducers: {
    resetPrism: () => initialState,
    // set prism obj
    setPrismObj: (state, action) => {
      state.prismObj = action.payload;
    },
    // set selected knacks
    setSelectedKnacks: (state, action) => {
      state.selectedKnacks = action.payload;
    },
    // get prism obj
    getPrismObj: (state) => state.prismObj,
    // get selected knacks
    getSelectedKnacks: (state) => state.selectedKnacks,
    // set to upload files
    setToUploadFilesPrism: (state, action) => {
      state.toUploadFiles = action.payload;
    },
    // get toUpload fi;es
    getToUploadFiles: (state) => state.toUploadFiles,
    // set id on edit
    setIdOnEdit: (state, action) => {
      state.idOnEdit = action.payload;
    },
    // get id on edit
    getIdOnEdit: (state) => state.idOnEdit,
    // set selected knacks template
    setSelectedKnacksTemplate: (state, action) => {
      state.selectedKnacksTemplate = action.payload;
    },
    // set prismOnView state
    setPrismOnView: (state, action) => {
      state.prismOnView = action.payload;
    },
    setUserInView: (state, action) => {
      state.userInView = action.payload;
    },
    updateQuery: (state, action) => {
      state.query = action.payload;
    },
    updateResults: (state, action) => {
      state.results = action.payload;
    },
    storePrismList: (state, action) => {
      state.prismList = action.payload
    }
  },
});

export const {
  resetPrism,
  setPrismObj,
  getPrismObj,
  getSelectedKnacks,
  setSelectedKnacks,
  setToUploadFilesPrism,
  getToUploadFiles,
  setIdOnEdit,
  getIdOnEdit,
  setSelectedKnacksTemplate,
  setPrismOnView,
  setUserInView,
  updateQuery,
  updateResults,
  storePrismList
} = prismSlice.actions;

export default prismSlice.reducer;
