import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { baseApiSlice } from "services/api/baseApiSlice";

import authReducer from "./auth/authSlice";
import extrasReducer from "./extras/extrasSlice";
import knackReducer from "./knacks/knacksSlice";
import pipelineReducer from "./pipeline/pipelineSlice";
import prismReducer from "./prism/prismSlice";
import talentReducer from "./talent/talentSlice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [baseApiSlice.reducerPath],
};

const reducers = combineReducers({
  [baseApiSlice.reducerPath]: baseApiSlice.reducer,
  prism: prismReducer,
  auth: authReducer,
  knacks: knackReducer,
  pipeline: pipelineReducer,
  talent: talentReducer,
  extras: extrasReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const makeStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApiSlice.middleware),
});

setupListeners(makeStore.dispatch);

export const persistor = persistStore(makeStore);
