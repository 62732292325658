import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  APPLE_AUTH,
  BASE_TALENTFEED_URL,
  BUSINESS,
  BUSINESS_AUTH_CHANGE_PASSWORD,
  BUY_SUPERLIKES,
  CHANGE_PASSWORD,
  CHANNELS,
  CHARGE,
  CREATE_PRISM,
  FACEBOOK_AUTH,
  FORGOT_PASSWORD,
  GAMES,
  GET_ALL_TEMPLATES,
  GET_GROUP_KNACKS,
  GET_HANDLES,
  GET_KNACKS,
  GET_USER,
  GOOGLE_AUTH,
  PIPELINE,
  PRISMS,
  RESEND_OTP,
  SCORES,
  SIGN_IN,
  SIGN_UP,
  SUBSCRIPTIONS,
  TALENT,
  TEMPLATE_CATEGORIES,
  TEMPLATE_SKILLS,
  UPDATE_TALENT,
  UPLOAD_IMAGES,
  VALIDATE_HANDLE,
  VERIFY_OTP,
} from "services/apiRoutes";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL_V1,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    const tokenInLocalStorage = localStorage.getItem("token");
    if (tokenInLocalStorage) {
      headers.set("authorization", `Bearer ${tokenInLocalStorage}`);
    }
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseApiSlice = createApi({
  baseQuery,
  refetchOnReconnect: true,
  tagTypes: ["Dashboard", "TalentFeed"],
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: ({ user, userDetails }) => ({
        url: `${user}/${SIGN_UP}`,
        method: "POST",
        body: userDetails,
      }),
    }),
    loginUser: builder.mutation({
      query: ({ user, details }) => ({
        url: `${user}/${SIGN_IN}`,
        method: "POST",
        body: details,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ userPath, userEmail }) => ({
        url: `${userPath}/${FORGOT_PASSWORD}`,
        method: "POST",
        body: userEmail,
      }),
    }),
    changePassword: builder.mutation({
      query: ({ userPath, details }) => ({
        url: `${userPath}/${CHANGE_PASSWORD}`,
        method: "POST",
        body: details,
      }),
    }),
    verifyOTP: builder.mutation({
      query: ({ userPath, details }) => ({
        url: `${userPath}/${VERIFY_OTP}`,
        method: "POST",
        body: details,
      }),
    }),
    resendOTP: builder.mutation({
      query: ({ user, body }) => ({
        url: `${user}/${RESEND_OTP}`,
        method: "POST",
        body,
      }),
    }),
    registerUserGoogle: builder.mutation({
      query: ({ details }) => ({
        url: `talents/${GOOGLE_AUTH}`,
        method: "POST",
        body: details,
      }),
    }),
    registerUserFacebook: builder.mutation({
      query: ({ userPath, details }) => ({
        url: `${userPath}/${FACEBOOK_AUTH}`,
        method: "POST",
        body: details,
      }),
    }),
    registerUserApple: builder.mutation({
      query: (details) => ({
        url: `talent/${APPLE_AUTH}`,
        method: "POST",
        body: details,
      }),
    }),
    verifyUserHandle: builder.mutation({
      query: ({ userPath, body }) => ({
        url: `${userPath}/${VALIDATE_HANDLE}`,
        method: "POST",
        body,
      }),
    }),
    // create prism - POST
    createPrism: builder.mutation({
      query: ({ obj }) => ({
        url: `${CREATE_PRISM}`,
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["Dashboard"],
    }),
    // get all templates - GET
    getTemplates: builder.query({
      query: () => ({
        url: `${GET_ALL_TEMPLATES}`,
        method: "GET",
      }),
      // keep cache for 7 days
      keepUnusedDataFor: 7 * 24 * 60 * 60 * 1000,
    }),
    // get all knacks - GET
    getKnacks: builder.query({
      query: () => ({
        url: `${GET_KNACKS}`,
        method: "GET",
      }),
      // keep cache for 7 days
      keepUnusedDataFor: 7 * 24 * 60 * 60 * 1000,
    }),
    // get all categories - GET
    getKnackGroups: builder.query({
      query: () => ({
        url: `${GET_GROUP_KNACKS}`,
        method: "GET",
      }),
      // keep cache for 7 days
      keepUnusedDataFor: 7 * 24 * 60 * 60 * 1000,
    }),
    // get user profile - GET
    getUser: builder.mutation({
      query: () => ({
        url: `${process.env.NEXT_PUBLIC_API_BASE_URL}${GET_USER}`,
        method: "GET",
      }),
    }),
    getUserFetch: builder.query({
      query: () => ({
        url: `${process.env.NEXT_PUBLIC_API_BASE_URL}${GET_USER}`,
        method: "GET",
      }),
    }),
    // get user - GET - param: id
    getBusiness: builder.mutation({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      query: ({ type, id }) => ({
        url: `business/${id}`,
        method: "GET",
      }),
    }),
    getBusinessFetch: builder.query({
      query: ({ type, userId }) => ({
        url: `${type}/${userId}`,
        method: "GET",
      }),
    }),
    // patch business user - PATCH - param: businessId
    patchBusiness: builder.mutation({
      query: ({ id, body }) => ({
        url: `${BUSINESS}/${id}`,
        method: "PATCH",
        body,
      }),
    }),
    // get template categories - GET
    getTemplateCategories: builder.query({
      query: () => ({
        url: `${TEMPLATE_CATEGORIES}`,
        method: "GET",
      }),
    }),
    // get template categories - GET
    getTemplateSkills: builder.query({
      query: () => ({
        url: `${TEMPLATE_SKILLS}`,
        method: "GET",
      }),
      // keep cache for 7 days
      keepUnusedDataFor: 7 * 24 * 60 * 60 * 1000,
    }),
    // get one template - GET
    getOnePrism: builder.query({
      query: (id) => ({
        url: `${PRISMS}/${id}`,
        method: "GET",
      }),
    }),
    // update one template - PATCH
    updatePrism: builder.mutation({
      query: ({ id, body }) => ({
        url: `${PRISMS}/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Dashboard"],
    }),
    // delete one template - DELETE
    deletePrism: builder.mutation({
      query: (id) => ({
        url: `${PRISMS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Dashboard"],
    }),
    // upload media files - POST - an array of files
    uploadMedia: builder.mutation({
      query: ({ id, body }) => ({
        url: `prisms/${id}/${UPLOAD_IMAGES}`,
        method: "PATCH",
        body,
      }),
    }),
    // change business password - POST
    changeBusinessPassword: builder.mutation({
      query: ({ body }) => ({
        url: `${BUSINESS_AUTH_CHANGE_PASSWORD}`,
        method: "POST",
        body,
      }),
    }),
    // PIPELINES
    // create pipeline
    createPipeline: builder.mutation({
      query: ({ pipelineObj }) => ({
        url: `${PIPELINE}/new`,
        method: "POST",
        body: pipelineObj,
      }),
    }),
    // update one pipeline - PATCH
    updatePipeline: builder.mutation({
      query: ({ id, body }) => ({
        url: `${PIPELINE}/${id}`,
        method: "PATCH",
        body,
      }),
    }),
    // get one pipeline - GET
    getOnePipeline: builder.query({
      query: (id) => ({
        url: `${PIPELINE}/${id}`,
        method: "GET",
      }),
    }),
    // delete one template - DELETE
    deletePipeline: builder.mutation({
      query: (id) => ({
        url: `${PIPELINE}/${id}`,
        method: "DELETE",
      }),
    }),
    // get all channels - GET
    getChannels: builder.query({
      query: () => ({
        url: `${CHANNELS}/all`,
        method: "GET",
      }),
    }),
    // like a channel - POST
    likeChannel: builder.mutation({
      query: ({ body }) => ({
        url: `${CHANNELS}/like`,
        method: "POST",
        body,
      }),
    }),
    // like a channel - POST
    superLikeChannel: builder.mutation({
      query: ({ body }) => ({
        url: `${CHANNELS}/superlike`,
        method: "POST",
        body,
      }),
    }),
    // MAPS - results - GET - param: id
    getMapsResults: builder.query({
      query: (userId) => ({
        url: `${SCORES}/${userId}`,
        method: "GET",
      }),
    }),
    // get prism using invite code - GET - param: inviteCode
    getPrismByInviteCode: builder.query({
      query: (inviteCode) => ({
        url: `${PRISMS}/code/${inviteCode}`,
        method: "GET",
      }),
    }),
    // get talent details
    getTalentDetails: builder.query({
      query: (id) => ({
        url: `${TALENT}/${id}`,
        method: "GET",
      }),
    }),
    // update talent personal details
    updateTalentDetails: builder.mutation({
      query: ({ userId, newData }) => ({
        url: `${UPDATE_TALENT}/${userId}`,
        method: "PATCH",
        body: newData,
      }),
    }),
    // get all talents
    getAllTalents: builder.query({
      query: () => ({
        url: "/talents",
        method: "GET",
      }),
    }),
    // charge a user - buy -
    chargeUser: builder.mutation({
      query: ({ body }) => ({
        url: `${process.env.NEXT_PUBLIC_API_BASE_URL}${CHARGE}`,
        method: "POST",
        body,
      }),
    }),
    // charge a user - buy -
    buySuperlikes: builder.mutation({
      query: ({ body }) => ({
        url: `${BUY_SUPERLIKES}`,
        method: "POST",
        body,
      }),
    }),
    // get all handles - GET_HANDLES
    getHandles: builder.query({
      query: () => ({
        url: `${GET_HANDLES}`,
        method: "GET",
      }),
    }),
    // get games info = GET
    getGamesInfo: builder.query({
      query: ({ userId, mysqlId }) => ({
        url: `${GAMES}/${userId}/${mysqlId}`,
        method: "GET",
      }),
    }),
    // get one template - GET
    getOneTemplate: builder.query({
      query: (id) => ({
        url: `templates/${id}`,
        method: "GET",
      }),
    }),

    // get feed for all talents that have completed games
    getAllTalentsFeed: builder.query({
      query: ({ id, page }) => ({
        url: `${BASE_TALENTFEED_URL}feed/${id}/${page}`,
        method: "GET",
      }),
      providesTags: ["TalentFeed"],
    }),

    // get feed for all talents that have completed games
    getAllInProgressPlayers: builder.query({
      query: (id) => ({
        url: `${BASE_TALENTFEED_URL}feed/inprogress/${id}`,
        method: "GET",
      }),
    }),

    // get stats
    getAllStatsForPrisms: builder.query({
      query: (id) => ({
        url: `${BASE_TALENTFEED_URL}stats/${id}`,
        method: "GET",
      }),
    }),

    // get evalution report for a particular prism
    getEvalutionReportForAprism: builder.query({
      query: (id) => ({
        url: `${BASE_TALENTFEED_URL}compute/${id}`,
        method: "GET",
      }),
    }),

    // get evaluation for a talent in a particular prism
    getEvaluationRaeportForATalent: builder.query({
      query: ({ id, talent }) => ({
        url: `${BASE_TALENTFEED_URL}report/${id}/${talent}`,
        method: "GET",
      }),
      invalidatesTags: ["Dashboard", "TalentFeed"],
    }),

    // unlock report using credits
    unlockReportWithCredits: builder.mutation({
      query: ({ body }) => ({
        url: `${BASE_TALENTFEED_URL}unlock/credits`,
        method: "POST",
        body,
      }),
    }),

    // unlock report using stripe
    unlockReportWithStripe: builder.mutation({
      query: ({ body }) => ({
        url: `${BASE_TALENTFEED_URL}unlock/stripe`,
        method: "POST",
        body,
      }),
    }),

    // buy credits
    buyCredits: builder.mutation({
      query: ({ body }) => ({
        url: `business/buycredits`,
        method: "POST",
        body,
      }),
    }),

    // Get a category report for an indvidual talent in a prism
    getAllCategoryKnacks: builder.query({
      query: ({ id, talent, tab }) => ({
        url: `${BASE_TALENTFEED_URL}report/${id}/${talent}/${tab}`,
        method: "GET",
      }),
    }),
    getAllCategoryKnacksSend: builder.mutation({
      query: ({ id, talent, tab }) => ({
        url: `${BASE_TALENTFEED_URL}report/${id}/${talent}/${tab}`,
        method: "GET",
      }),
    }),
    // Get a category report for an indvidual talent in a prism
    getAllSkillsCategory: builder.query({
      query: ({ prismId, talentId }) => ({
        url: `${BASE_TALENTFEED_URL}report/${prismId}/${talentId}/categories/allskills`,
        method: "GET",
      }),
    }),
    getAllSkillsCategorySend: builder.mutation({
      query: ({ prismId, talentId }) => ({
        url: `${BASE_TALENTFEED_URL}report/${prismId}/${talentId}/categories/allskills`,
        method: "GET",
      }),
    }),
    // add user to waitlist
    addUserToWaitlist: builder.mutation({
      query: ({ body }) => ({
        url: `waitlist`,
        method: "POST",
        body,
      }),
    }),

    // get all business prisms
    getAllBusinessPrisms: builder.query({
      query: ({ userId, page }) => ({
        url: `prisms/business/all/${userId}?page=${page}`,
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),
    // 2fa hooks
    activate2fa: builder.query({
      query: () => ({
        url: `${process.env.NEXT_PUBLIC_API_BASE_URL}auth/2fa/register`,
        method: "GET",
      }),
    }),
    firstTimeAuthenticate: builder.mutation({
      query: ({ body }) => ({
        url: `business/auth/2fa/first-time-authenticate`,
        method: "POST",
        body,
      }),
    }),
    loginWith2fa: builder.mutation({
      query: ({ body }) => ({
        url: `business/auth/2fa/signin`,
        method: "POST",
        body,
      }),
    }),

    cleanUpSubscriptions: builder.query({
      query: (businessId) => ({
        url: `business/stripe/cleanup-subscriptions?businessId=${businessId}`,
        method: "POST",
      }),
    }),

    // get all business pipelines
    getAllBusinessPipelines: builder.query({
      query: (userId) => ({
        url: `pipelines/owner/${userId}`,
        method: "GET",
      }),
    }),

    // get all prisms
    getAllPrisms: builder.query({
      query: () => ({
        url: `prisms/all`,
        method: "GET",
      }),
    }),

    // get required games
    getRequiredGames: builder.mutation({
      query: ({ body }) => ({
        url: `${process.env.NEXT_PUBLIC_INTERNAL_API_URL}required-games`,
        method: "POST",
        body,
      }),
    }),

    // get game status
    getGameStatus: builder.query({
      query: ({ mysqlId, requiredGames }) => ({
        url: `${process.env.NEXT_PUBLIC_INTERNAL_API_URL}get-status`,
        method: "POST",
        body: {
          mysqlId,
          requiredGames,
        },
      }),
    }),
    getGameStatusPost: builder.mutation({
      query: ({ mysqlId, requiredGames }) => ({
        url: `${process.env.NEXT_PUBLIC_INTERNAL_API_URL}get-status`,
        method: "POST",
        body: {
          mysqlId,
          requiredGames,
        },
      }),
    }),

    // post comment for talent report
    postComment: builder.mutation({
      query: ({ body, prismId, talentId }) => ({
        url: `${BASE_TALENTFEED_URL}report/${prismId}/${talentId}/comment`,
        method: "PATCH",
        body,
      }),
    }),

    // SUBSCRIPTIONS
    // subscribe to a prime
    activateSubscription: builder.mutation({
      query: ({ body }) => ({
        url: `business/subscribe/${body.businessId}`,
        method: "POST",
        body,
      }),
    }),
    // cancel subscription
    cancelSubscription: builder.mutation({
      query: ({ body }) => ({
        url: `${process.env.NEXT_PUBLIC_API_BASE_URL}${SUBSCRIPTIONS}/cancel`,
        method: "POST",
        body,
      }),
    }),

    // stripe - stripe/create-payment-intent
    createPaymentIntent: builder.query({
      query: (amount) => ({
        url: `stripe/create-payment-intent`,
        method: "POST",
        body: {
          amount,
        },
      }),
    }),

    // activate prism
    activatePrism: builder.mutation({
      query: ({ prismId, businessId }) => ({
        url: `prisms/activate`,
        method: "POST",
        body: {
          prismId,
          businessId,
        },
      }),
    }),

    // get ALL businesses
    getAllBusinesses: builder.query({
      query: () => ({
        url: `${process.env.NEXT_PUBLIC_PROD_API}business`,
        method: "GET",
      }),
    }),
    // get waitlist - careerGuru
    getWaitlist: builder.query({
      query: () => ({
        url: `${process.env.NEXT_PUBLIC_PROD_API}waitlist`,
        method: "GET",
      }),
    }),
    // get waitlist
    getGuruWaitlist: builder.query({
      query: () => ({
        url: `${process.env.NEXT_PUBLIC_PROD_API}guru-waitlist`,
        method: "GET",
      }),
    }),
    // get waitlist
    getAscendWaitlist: builder.query({
      query: () => ({
        url: `${process.env.NEXT_PUBLIC_PROD_API}ascend-waitlist`,
        method: "GET",
      }),
    }),
    getSuperstarWaitlist: builder.query({
      query: () => ({
        url: `${process.env.NEXT_PUBLIC_PROD_API}super-star`,
        method: "GET",
      }),
    }),

    // export pdf
    downloadPdfFull: builder.mutation({
      query: ({ body }) => ({
        url: `prisms/download/report/full`,
        method: "POST",
        body,
      }),
    }),

    // export pdf
    downloadPdfStrengths: builder.mutation({
      query: ({ body }) => ({
        url: `prisms/download/report/strengths`,
        method: "POST",
        body,
      }),
    }),

    // export csv
    downloadCsv: builder.mutation({
      query: ({ body }) => ({
        url: `prisms/talentfeed/download/csv?talentId=${body.talentId}&prismId=${body.prismId}&resource=${body.resource}`,
        method: "GET",
      }),
    }),

    // get all users in a session
    GetUsersSessions: builder.query({
      query: (userid) => ({
        url: `session/${userid}`,
        method: "GET",
      }),
    }),

    getNewsletterUsers: builder.query({
      query: () => ({
        url: `${process.env.NEXT_PUBLIC_PROD_API}news-letter`,
        method: "GET",
      }),
    }),

    GetLogout: builder.mutation({
      query: () => ({
        url: `business/auth/logout`,
        method: "GET",
      }),
    }),
    GetBulkEmails: builder.mutation({
      query: ({ body }) => ({
        url: `prisms/talents/invite-bulk`,
        method: "POST",
        body,
      }),
    }),
    verifyEmailOtp: builder.mutation({
      query: ({ userPath, details }) => ({
        url: `${userPath}/${"auth/local/verifyotp-update"}`,
        method: "POST",
        body: details,
      }),
    }),
    subscribeNewsLetter: builder.mutation({
      query: ({ body }) => ({
        url: `news-letter`,
        method: "POST",
        body,
      }),
    }),
    sendReminders: builder.mutation({
      query: (_id) => ({
        url: `prisms/talentfeed/in-progress/reminder/${_id}`,
        method: "POST",
      }),
    }),
    addTalentToShortlist: builder.mutation({
      query: ({ body }) => ({
        url: `prisms/talentfeed/shortlist-talent`,
        method: "POST",
        body,
      }),
    }),
    assignRole: builder.mutation({
      query: ({ body }) => ({
        url: `business/assignRole`,
        method: "POST",
        body,
      }),
    }),
    getPrismNamefromId: builder.query({
      query: (prismID) => ({
        url: `prisms/name/${prismID}`,
        method: "GET",
      }),
    }),
    getTalentBasicBio: builder.query({
      query: (userID) => ({
        url: `talents/basic-bio/${userID}`,
      }),
    }),
    exportToCSV: builder.mutation({
      query: ({ body }) => ({
        url: `prisms/talentfeed/report/email-csv`,
        method: "POST",
        body,
      }),
    }),
    updateRole: builder.mutation({
      query: ({ body }) => ({
        url: `business/invite/update-permissions`,
        method: "PATCH",
        body,
      }),
    }),
    deleteUser: builder.mutation({
      query: (email) => ({
        url: `business/invite/delete-user?email=${email}`,
        method: "DELETE",
      }),
    }),
    getInvitedMembers: builder.query({
      query: () => ({
        url: `business/invited-users/permissions`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useResetPasswordMutation,
  useVerifyOTPMutation,
  useResendOTPMutation,
  useChangePasswordMutation,
  useRegisterUserGoogleMutation,
  useRegisterUserFacebookMutation,
  useRegisterUserAppleMutation,
  useVerifyUserHandleMutation,
  useCreatePrismMutation,
  useGetTemplatesQuery,
  useGetKnacksQuery,
  useGetKnackGroupsQuery,
  useGetUserMutation,
  useGetBusinessMutation,
  usePatchBusinessMutation,
  useGetTemplateCategoriesQuery,
  useGetTemplateSkillsQuery,
  useGetOnePrismQuery,
  useUpdatePrismMutation,
  useDeletePrismMutation,
  useUploadMediaMutation,
  useChangeBusinessPasswordMutation,
  useCreatePipelineMutation,
  useUpdatePipelineMutation,
  useGetOnePipelineQuery,
  useDeletePipelineMutation,
  useGetChannelsQuery,
  useLikeChannelMutation,
  useSuperLikeChannelMutation,
  useGetMapsResultsQuery,
  useGetPrismByInviteCodeQuery,
  useGetTalentDetailsQuery,
  useUpdateTalentDetailsMutation,
  useGetAllTalentsQuery,
  useChargeUserMutation,
  useBuySuperlikesMutation,
  useGetHandlesQuery,
  useGetGamesInfoQuery,
  useGetOneTemplateQuery,
  useGetAllTalentsFeedQuery,
  useGetAllStatsForPrismsQuery,
  useGetEvalutionReportForAprismQuery,
  useGetEvaluationRaeportForATalentQuery,
  useUnlockReportWithCreditsMutation,
  useUnlockReportWithStripeMutation,
  useBuyCreditsMutation,
  useGetAllCategoryKnacksQuery,
  useAddUserToWaitlistMutation,
  useGetAllBusinessPrismsQuery,
  useGetAllBusinessPipelinesQuery,
  useGetAllSkillsCategoryQuery,
  useGetAllPrismsQuery,
  useGetRequiredGamesMutation,
  usePostCommentMutation,
  useCancelSubscriptionMutation,
  useCreatePaymentIntentQuery,
  useActivatePrismMutation,
  useGetAllInProgressPlayersQuery,
  useGetAllBusinessesQuery,
  useGetWaitlistQuery,
  useDownloadCsvMutation,
  useGetAllCategoryKnacksSendMutation,
  useGetAllSkillsCategorySendMutation,
  useDownloadPdfFullMutation,
  useDownloadPdfStrengthsMutation,
  useGetGameStatusQuery,
  useGetBusinessFetchQuery,
  useGetUserFetchQuery,
  useActivateSubscriptionMutation,
  useGetUsersSessionsQuery,
  useGetLogoutMutation,
  useGetGuruWaitlistQuery,
  useGetAscendWaitlistQuery,
  useGetBulkEmailsMutation,
  useVerifyEmailOtpMutation,
  useSubscribeNewsLetterMutation,
  useAssignRoleMutation,
  useGetNewsletterUsersQuery,
  useGetGameStatusPostMutation,
  useSendRemindersMutation,
  useAddTalentToShortlistMutation,
  useGetPrismNamefromIdQuery,
  useGetTalentBasicBioQuery,
  useExportToCSVMutation,
  useGetSuperstarWaitlistQuery,
  useGetInvitedMembersQuery,
  useCleanUpSubscriptionsQuery,
  useActivate2faQuery,
  useFirstTimeAuthenticateMutation,
  useLoginWith2faMutation,
  useDeleteUserMutation,
  useUpdateRoleMutation,
} = baseApiSlice;
